@font-face{
    font-family: 'MuseoSansRegular';
    src: url('media/fonts/MuseoSans_300.otf');
}

@font-face{
    font-family: 'MuseoSansBold';
    src: url('media/fonts/MuseoSans_700.otf');
}

::-webkit-scrollbar {width: 11px;}
::-webkit-scrollbar-track {background: #f1f1f1; border-radius: 6px 4px 4px 6px; border-left: 4px solid white;}
::-webkit-scrollbar-thumb {background: #888; border-radius: 6px 4px 4px 6px; border-left: 4px solid white;}
::-webkit-scrollbar-thumb:hover {background: #555;}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px white inset !important;
}

/****** TOOLTIP ******/
[data-tooltip] {
  position: relative;
  z-index: 10;
}

[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: calc(100% + 5px);
  bottom: calc(100% + 5px);
  pointer-events: none;
    transition: 0.2s;
    will-change: transform;
  }
  
  [data-tooltip]:before {
    content: attr(data-tooltip);
    padding: 7px;
    min-width: 50px;
    max-width: 300px;
    width: max-content;
    width: -moz-max-content;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'MuseoSansRegular', sans-serif;
    background-color: rgba(59, 72, 80, 0.9);
    background-image: linear-gradient(30deg,
    rgba(59, 72, 80, 0.44),
    rgba(59, 68, 75, 0.44),
        rgba(60, 82, 88, 0.44));
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
    white-space: pre-wrap;
    transform: translate(-50%, -5px) scale(0.5);
  }
  
  [data-tooltip]:after {
    content: '';
    left: 40px;
    border-style: solid;
    border-width: 5px 5px 0px 5px;
    border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
    transition-duration: 0s;
    transform-origin: top;
    transform: translateX(-50%) scaleY(0);
  }
  
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
  }
  
  [data-tooltip]:hover:before {
    transition-delay: 0.3s;
    transform: translate(-50%, -5px) scale(1);
  }
  
  [data-tooltip]:hover:after {
    transition-delay: 0.5s;
    transition-duration: 0.2s;
    transform: translateX(-50%) scaleY(1);
  }